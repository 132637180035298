import { useState, useEffect } from "react";
import "./App.css";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

const VOWELS = ["a", "e", "i", "o", "u"];
const CONSONANTS = [
  "b",
  "c",
  "d",
  "f",
  "g",
  "h",
  "j",
  "k",
  "l",
  "m",
  "n",
  "p",
  "q",
  "r",
  "s",
  "t",
  "v",
  "w",
  "x",
  "y",
  "z",
];
const maxRetries = 3;

function isVowel(char) {
  return VOWELS.includes(char.toLowerCase());
}

function isConsonant(char) {
  return CONSONANTS.includes(char.toLowerCase());
}

function App() {
  const [algorithm, setAlgorithm] = useState("");
  const [name, setName] = useState("");
  const [rememberedNames, setRememberedNames] = useState([]);
  const [windowSize, setWindowSize] = useState(2);
  const [text, setText] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("Voer voorbeeld in en klik op genereer");

  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/OpenTaal/opentaal-wordlist/master/basiswoorden-gekeurd.txt"
    ).then((response) =>
      response.text().then((text) => {
        setText(text.replace(/(\r\n|\n|\r)/gm, " ").split(""));
        setLoaded(true);
      })
    );
  }, []);

  const next = () => {
    const chosenCharacters = algorithm.split("").reduce(
      (acc, char, algorithmIndex, algorithmArray) => {
        if (!acc.hasOwnProperty(char)) {
          function getNextCharacter() {
            const startIndex = Math.floor(Math.random() * text.length);
            const sample = text.slice(startIndex, text.length);
            let j = windowSize + algorithmIndex;
            while (
              j < sample.length &&
              (!matchesPreviousCharacters(j) ||
                !(
                  isVowel(sample[j]) === isVowel(char) &&
                  isConsonant(sample[j]) === isConsonant(char)
                ))
            ) {
              j += 1;
            }
            if (j < sample.length) {
              acc[char.toLowerCase()] = sample[j].toLowerCase();
            } else {
              throw new Error(
                "Niet gelukt. Gebruik een lagere Nederlandsheid of een minder complexe voorbeeldnaam. Of probeer het nog eens."
              );
            }

            function matchesPreviousCharacters(j) {
              let matchString = sample
                .slice(Math.max(j - algorithmIndex - 1, j - windowSize), j)
                .join("");
              const nameUntilNow = (" " + algorithm)
                .split("")
                .slice(
                  Math.max(0, algorithmIndex + 1 - windowSize),
                  algorithmIndex + 1
                )
                .map((char) => acc[char])
                .join("");
              return matchString === nameUntilNow;
            }
          }
          getNextCharacter();
        }
        return acc;
      },
      { " ": " " }
    );
    let newName = algorithm
      .split("")
      .map((char) => chosenCharacters[char.toLowerCase()])
      .join("");
    setName(newName);
  };

  const remember = () => {
    setRememberedNames([...rememberedNames, name]);
  };

  const handleNextButton = (tries) => {
    setName("...");
    setError("");
    setTimeout(() => {
      try {
        next();
      } catch (e) {
        if (tries < maxRetries) {
          handleNextButton(++tries);
        } else {
          setName("");
          setError(e.message);
        }
      }
    }, 1);
  };

  if (!loaded) {
    return (
      <div className="App">
        <header className="App-header">Laden...</header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ fontSize: 12 }}>Voorbeeldnaam: </div>
        <div>
          <input
            type="text"
            value={algorithm}
            onChange={(e) => setAlgorithm(e.target.value)}
          />
        </div>
        <h1 style={{ textTransform: "capitalize" }}>{name}</h1>
        <div
          style={{ fontSize: 16, padding: 16, color: "lightgoldenrodyellow" }}
        >
          {error}
        </div>
        <div>
          <Typography id="discrete-slider" gutterBottom>
            Nederlandsheid {windowSize}
          </Typography>

          <Slider
            defaultValue={2}
            value={windowSize}
            onChange={(e, value) => setWindowSize(value)}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={5}
          />
        </div>
        <div>
          <button onClick={() => handleNextButton(0)}>genereer</button>{" "}
          <button onClick={remember}>onthoud</button>
        </div>
        <div>
          <ul>
            {rememberedNames.map((name, i) => (
              <li key={`remembered-name-${i}`}>{name}</li>
            ))}
          </ul>
        </div>
        <div></div>
      </header>
    </div>
  );
}

export default App;
